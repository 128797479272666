import { Text } from "@clipboard-health/ui-react";
import { CircularProgress, Stack } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useEffect } from "react";

import { CbhIcon } from "../../components/CbhIcon";
import { type PlacementCandidate } from "../types";

interface PendingResumeProps {
  placementCandidate: PlacementCandidate;
  refreshCallback: () => Promise<void>;
}

export function BodyPending({ refreshCallback }: Pick<PendingResumeProps, "refreshCallback">) {
  useEffect(() => {
    const timer = setTimeout(async () => {
      await refreshCallback();
    }, 30_000);

    return () => {
      clearTimeout(timer);
    };
  }, [refreshCallback]);

  return (
    <Stack direction="row" spacing={3} alignItems="center">
      <CircularProgress size={16} />
      <Text variant="body2">
        Your resume is being processed.
        <br />
        Return in a few minutes.
      </Text>
    </Stack>
  );
}

export function BodyInvalid({
  placementCandidate: { pendingResume },
}: Pick<PendingResumeProps, "placementCandidate">) {
  const parsingDecisionReason = pendingResume?.parsingDecisionReason;

  return (
    <Stack direction="column" spacing={1} sx={(theme) => ({ color: theme.palette.error.main })}>
      <Stack direction="row" spacing={1} alignItems="center">
        <CbhIcon type="close" color={(theme) => theme.palette.error.main} />
        <Text color={(theme) => theme.palette.error.main}>
          We detected some issues in your resume. Please fix them and re-upload.
          {parsingDecisionReason && (
            <>
              {" "}
              Detected issue
              {parsingDecisionReason?.length > 1 ? "s" : ""}:{" "}
              {parsingDecisionReason
                ?.map(
                  (reason) =>
                    reason.replaceAll("_", " ").charAt(0).toUpperCase() +
                    reason.replaceAll("_", " ").slice(1)
                )
                .join(", ")}
            </>
          )}
        </Text>
      </Stack>
    </Stack>
  );
}

export function PendingResume({ placementCandidate, refreshCallback }: PendingResumeProps) {
  const { pendingResume } = placementCandidate;
  const pendingResumeStatus = pendingResume?.status;

  if (!pendingResume || pendingResumeStatus === "no_file" || pendingResumeStatus === "valid") {
    return null;
  }

  return (
    <Stack direction="column" spacing={1} sx={{ mt: 6 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Text variant="body1">Last uploaded resume</Text>
        {pendingResume.updatedAt && (
          <Text variant="caption" sx={{ alignSelf: "flex-end" }}>
            {formatDistanceToNow(pendingResume.updatedAt, { addSuffix: true })}
          </Text>
        )}
      </Stack>

      <Stack direction="column" spacing={1}>
        {pendingResumeStatus === "pending" && <BodyPending refreshCallback={refreshCallback} />}
        {pendingResumeStatus === "invalid" && (
          <BodyInvalid placementCandidate={placementCandidate} />
        )}
      </Stack>
    </Stack>
  );
}
